@import "variables";
@import "bootstrap/scss/bootstrap";

// Icons
@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined');
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

@import "pages/general";

@import "layouts/root_layout";
@import "layouts/auth_layout";
@import "layouts/sidenav";
@import "layouts/topbar";
@import "layouts/footer";

@import "components/dropdown";
@import "components/page-head";
@import "components/folder-item";
@import "components/card";
@import "components/tables";
@import "components/buttons";
@import "components/breadcrumb";
@import "components/preloader";
@import "components/gallery";
@import "components/type";
@import "components/roi";
@import "components/select_ribbon";
@import "components/modal";
@import "components/pagination";
@import "components/cam-status";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  text-decoration: none !important;
  outline: none;
}

p {
  line-height: 1.6;
  font-size: 15px;
}