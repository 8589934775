.modal-full-width {
  width: 60%;
  max-width: none;
}

.modal-top {
  margin: 0 auto;
}

.modal-title {
  margin-top: 0;
}