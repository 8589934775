.custom-modal-dialog {
    width: 70% !important;
    height: 80vh !important;
    max-width: none !important;
    margin: 5vh auto !important;
}

.custom-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.custom-modal-body {
    overflow-y: auto;
    flex-grow: 1;
}

.fancy-card {
    border-color: #007bff;
}

.fancy-card .card-body {
    background-color: #f0f8ff;
    text-indent: 7%;
}

.status-value {
    font-size: 25px;
    color: black;
}

.status-ok {
    color: green;
}

.status-alert {
    color: red;
    font-size: 20px;
}
.camera-status-element {
    padding: 0.1rem 0;
}

.camera-status-label {
    color: #495057;
    }

.camera-status-value {
    color: black;}

.camera-status-element:hover {
    background-color: #f8f9fa;}


.modal-content .popup-date-overlay .popup-image-overlay-text-logo {
    position: absolute;
    right: 0;
    bottom: 0;
    img {
        max-height: 40px;
    }
}

.fancy-alert {
    background: linear-gradient(to right, #ff416c, #ff4b2b);
    border: none;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .icon-bounce {
    animation: bounce 1s infinite alternate;
    color: #fff;
    }

    @keyframes bounce {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-10px);
    }
    }

