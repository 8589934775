
.wrapper {
  display: flex;
  flex: 1 1 auto;
  height: 100%;
  width: 100%;
}

.content-page {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  position: relative;
  transition: $menu-transition;

  .content {
    min-height: calc(100vh - $topbar-height);
     padding: 0 calc($spacer * 0.5) $footer-height;
  }
}
