.card {
    box-shadow: $card-box-shadow;
    margin-bottom: $grid-gutter-width;
}

.card-drop {
    font-size: 20px;
    line-height: 0;
    color: inherit;
}

.card-widgets {
    float: right;
    height: 16px;

    >a {
        color: inherit;
        font-size: 18px;
        display: inline-block;
        line-height: 1;

        &.collapsed {
            i {
                &:before {
                    content: "\F0415";
                }
            }
        }
    }
}

.card-title,
.card-header {
    margin-top: 0;
}

.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    background: rgba(var(--#{$prefix}secondary-bg-rgb), 0.8);
    cursor: progress;

    .card-portlets-loader {
        background-color: var(--#{$prefix}gray-900);
        animation: rotatebox 1.2s infinite ease-in-out;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 3px;
        margin-left: -($grid-gutter-width * 0.5);
        margin-top: -($grid-gutter-width * 0.5);
    }
}

@keyframes rotatebox {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

.header-title {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0 0 7px 0;
}

.sub-header {
    font-size: 0.875rem;
    margin-bottom: $grid-gutter-width;
    color: $text-muted;
}