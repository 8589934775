.btn {
    .mdi {
        &:before {
            line-height: initial;
        }
    }

    .spinner-grow,
    .spinner-border {
        vertical-align: middle;
    }

    &-rounded {
        border-radius: 50%;
    }

    &-xs {
        padding: 0.2rem 0.6rem;
        font-size: 0.75rem;
        border-radius: 0.15rem;
    }

    // Button labels
    &-label {
        margin: -0.55rem 0.9rem -0.55rem -0.9rem;
        padding: 0.6rem 0.9rem;
    }

    &-label-right {
        margin: (-$btn-padding-y) (-$btn-padding-x) (-$btn-padding-y) ($btn-padding-x);
        padding: ($btn-padding-y) ($btn-padding-x);
        display: inline-block;
    }

    i {
        display: inline-block;
    }
}

button:focus {
    outline: none;
}

.btn-primary {
    --#{$prefix}btn-color: #fff;
    --#{$prefix}btn-bg: var(--#{$prefix}primary);
    --#{$prefix}btn-hover-color: #fff;
    --#{$prefix}btn-hover-bg: var(--#{$prefix}primary-dark);
    &.btn-add {
        &:before {
            padding-right: 5px;
            content: "add";
            font-family: "Material Symbols Outlined";
            vertical-align: center;
        }
    }
}