.navbar-custom {
  background: $topbar-bg;
  min-height: $topbar-height;
  top: 0;
  position: sticky;
  transition: $menu-transition;
  z-index: 999;
  border: $card-border-width solid transparent;
  border-bottom: $card-border-width solid $card-border-color;
  box-shadow: var(--#{$prefix}box-shadow);

  .topbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 0 $spacer;


    ul {
      list-style-type: none;
      margin-bottom: 0;
    }


    li {
      max-height: $topbar-height;

      .nav-link {
        &.show {
          color: $topbar-item-hover-color;
        }
      }
    }

    .nav-link {
      padding: 0 $spacer * 0.75;
      position: relative;
      color: $topbar-item-color;
      display: flex;
      align-items: center;
      height: $topbar-height;
      cursor: pointer;

      &:hover {
        color: $topbar-item-hover-color;
      }
    }
  }
}

.nav-user {
  padding: 0 12px !important;

  img {
    height: 32px;
    width: 32px;
  }
}

@media (max-width: 375px) {
  .navbar-custom {
    .logo-box {
      display: none !important;
    }
  }

}

@include media-breakpoint-down(sm) {
  .navbar-custom {
    .topbar-menu {
      position: initial;
    }

    .dropdown {
      position: static;

      .dropdown-menu {
        left: 10px !important;
        right: 10px !important;
      }
    }
  }
}


@include media-breakpoint-down(md) {

  .navbar-custom {
    padding: 0 $spacer * 0.5;

    .logo-box {
      display: flex;

      .logo-lg {
        display: none;
      }

      .logo-sm {
        display: block;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .navbar-custom {
    .logo-box {
      display: none;
    }
  }
}