.folder-list-container {
  display: flex;
  gap: 1.2%;
  overflow: hidden;
  flex-wrap: wrap;
}

.folder-item {
  width: 220px;
  flex: initial;
  text-align: center;
  height: 140px;
  background-color: $folder-item-bg;
  border: None;
  margin-top: 1rem;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 0 1rem 1rem 1rem;

  /* Create top fold */
  &::before {
    content: "";
    position: absolute;
    width: 50%;
    border-radius: 1rem 0.1rem 0 0;
    top: -1rem;
    left: 0;
    border-bottom: 1rem solid $folder-item-bg;
    border-right: 1rem solid transparent;
  }

  &:hover, :focus {
    //cursor: pointer;
    background-color: $folder-item-hover-bg;
    &::before {
      border-bottom: 1rem solid $folder-item-hover-bg;
    }
  }

  &.selected {
    background-color: $folder-item-selected-bg;
    &::before {
      border-bottom: 1rem solid $folder-item-selected-bg;
    }
  }

  .folder-item-content {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: $folder-item-content-bg;
    padding: 1rem;

    .folder-title {
      font-size: 1rem;
      color: #8D8D8D;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

}