.table-centered {
  th,
  td {
    vertical-align: middle !important;
  }
}

.table {
  .table-user {
    img {
      height: 30px;
      width: 30px;
    }
  }

  .action-icon {
    color: var(--#{$prefix}gray-600);
    display: inline-block;
    padding: 0 3px;

    &:hover {
      color: var(--#{$prefix}gray-700);
    }
  }

  .action-delete:hover {
    color: var(--#{$prefix}danger);
  }

  > :not(caption) > * > * {
    background-color: var(--#{$prefix}table-bg) !important;
  }

  &.table-bordered {
    tbody {
      border-top: $border-width solid;
      border-top-color: inherit;
    }
  }
}

.table-sm {
  .action-icon {
    font-size: 1rem;
  }
}

.table-nowrap {

  th,
  td {
    white-space: nowrap;
  }
}