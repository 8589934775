.breadcrumb-item {
    > a {
        color: var(--#{$prefix}gray-700);
    }
    +.breadcrumb-item {
        &::before {
            content: "chevron_right";
            font-family: "Material Symbols Outlined";
        }
    }
}
