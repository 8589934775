.gallery-filter-menu {
  a {
    transition: all 0.3s ease-out;
    color: var(--#{$prefix}gray-900);
    border-radius: 3px;
    padding: 5px 10px;
    display: inline-block;
    margin-bottom: 5px;
    font-weight: $font-weight-medium;
    font-family: $font-family-secondary;

    &:hover {
      background-color: rgba($primary, 0.15);
      color: $primary;
    }

    &.active {
      background-color: $primary;
      color: $white;
    }
  }
}

.gallery-image-container {
  background-color: $card-bg;
  border-radius: 3px;
  box-shadow: $box-shadow;
  margin-bottom: $grid-gutter-width;
  flex: 1 1 20%;
  max-width: 25%;
  
  @media (min-width: 1730px) {
    flex: 1 1 15%;
  }

  .image-popup {
    text-align: center;
    padding: 10px;
    display: block;

    img {
      cursor: zoom-in;

    }
  }

  .gallery-image-info {
    padding: 15px;
    border-top: 1px solid var(--#{$prefix}gray-200);
    position: relative;

    h4 {
      display: block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .gallery-img-btns {
      position: absolute;
      right: 15px;
      font-size: 22px;
      top: 24px;
      display: flex;
      gap: 10px;
      .lock_unlock_media_icon.text-success {
          color: rgba(var(--csis-primary-rgb), var(--csis-text-opacity)) !important; 
      }
    }

    .gal-hd-btn {
      span {
        position: relative;
        bottom: 2px;
        left: 0;
        //font-weight: 600;
        font-size: 1.2rem;
      }
    }

    .hd-pending {
      &::after {
        font-family: "Material Symbols Outlined";
        content: "arrow_downward";
        position: absolute;
        padding: 0;
        margin: 0;
        color: $warning;
        font-weight: 600;
        top: -14px;
        font-size: 14px;
        z-index: 1;
        left: 5px;
      }
    }
  }
}

.popup-date-overlay {
  color: white;
  gap: 1rem;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex: 1;
  height: 40px;
  background: black;
  justify-content: center;
  align-items: center;
  letter-spacing: 2px;
  font-size: 1.2rem;
}
