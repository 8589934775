.auth-fluid {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
  flex-direction: row;
  align-items: stretch;
  background: $black center;
  background-size: cover;

  .auth-fluid-form-box {
    max-width: 480px;
    border-radius: 0;
    z-index: 2;
    padding: 3rem;
    background-color: $card-bg;
    position: relative;
    width: 100%;
  }

  .auth-fluid-right {
    padding: 6rem 3rem;
    flex: 1;
    position: relative;
    color: $white;
    background-color: rgba($black, 0.3);
  }
}

.auth-brand {
  margin-bottom: 2rem;
}

.auth-promotional-text {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 1.75rem;
  bottom: 3rem;
  right: 0;

  p.lead {
    font-size: 1.125rem;
    margin: 0 auto 20px auto;
    max-width: 700px;
  }
}

.auth-title {
  font-family: 'Roboto Condensed',sans-serif;
  font-size: 3rem;
  color: $primary;
}

.auth-phone {
  color: $white;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2em;
}


@include media-breakpoint-down(lg) {
  .auth-fluid {
    display: block;

    .auth-fluid-form-box {
      max-width: 100%;
      min-height: 100vh
    }

    .auth-fluid-right {
      display: none;
    }
  }
}

.auth-brand {
  margin-bottom: 2rem;

  .logo-dark {
    display: block;
  }

  .logo-light {
    display: none;
  }
}

.auth-video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
  direction: ltr;
}

.auth-video {
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 2545px;
  height: 1431.56px;
}

.auth-video-overlay {
  background-color: #101218;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: .65;
  transition: background 0.3s,border-radius 0.3s,opacity 0.3s;
}