.material-symbols-outlined {
  font-size: 20px;
}

.image-popup-icon-container {
  display: flex;
  gap: 1rem;
  align-self: end;

  .image-popup-icon {
    font-size: 36px;
    height: 2rem;
    width: 2rem;
    display: inline-block;
    background-position: 50%;
    background-size: 100% 100%;
    transition: opacity 0.15s ease;

  }
  .lock_unlock_media_icon.text-success {
    color: rgba(var(--csis-primary-rgb), var(--csis-text-opacity)) !important;
  }

  .image-popup-btn {
    background: none;
    border: none;
    text-align: center;
    padding: 0;

    &:hover,
    &:focus {
      text-decoration: none;
      outline: 0;
      background: none;
      opacity: 0.7;
    }
  }

  .hd-pending {
    &::after {
      font-family: "Material Symbols Outlined";
      content: "arrow_downward" !important;
      position: relative;
      padding: 0;
      margin: 0;
      color: #ffc107;
      font-weight: 600;
      top: -35px;
      font-size: 14px;
      z-index: 10000;
      left: -23px
    }
  }
}

.album-download-icon {
  font-size: 1.8rem;
  color: $primary;
  position: relative;
  bottom: 5px;
}

.filterable-content {
  margin-bottom: 7rem;
}
