.dropdown-menu {
  padding: 0.3rem;
  box-shadow: $box-shadow;
  animation-name: DropDownSlide;
  animation-duration: .3s;
  animation-fill-mode: both;
  top: 100%;

  &.show {
    top: 100% !important;
  }

  i {
    display: inline-block;
  }
}

.dropdown-menu-end {
  right: 0 !important;
  left: auto !important;
}

.dropdown-menu[data-popper-placement^=right],
.dropdown-menu[data-popper-placement^=top],
.dropdown-menu[data-popper-placement^=left] {
  top: auto !important;
  animation: none !important;
}

@keyframes DropDownSlide {
  100% {
    transform: translateY(0)
  }

  0% {
    transform: translateY(15px)
  }
}

.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  display: block;
  border: 1px solid transparent;
  color: $dropdown-link-color;

  img {
    height: 24px;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background-color: $dropdown-link-hover-bg;
  }
}

// Dropdown size
@include media-breakpoint-up(lg) {
  .dropdown-mega-menu-xl {
    width: 40rem;
  }

  .dropdown-mega-menu-lg {
    width: 26rem;
  }
}

.dropdown-menu {
  box-shadow: var(--#{$prefix}box-shadow);
}

.dropdown-header {
  margin-top: 0;
}

.dropdown-menu-animated {
  &.dropdown-menu-end[style] {
    left: auto;
    right: 0;
  }
}

.hover-dropdown {
  &:hover {
    .dropdown-menu {
      display: block !important;
      top: 100% !important;
    }
  }
}

// Dropdown Animated (Custom)
.dropdown-menu-animated {
  animation-name: DropDownSlide;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  position: absolute;
  margin: 0;
  z-index: 1000;

  &.show {
    top: 100% !important;
  }

  i {
    display: inline-block;
  }

  &.dropdown-menu[data-popper-placement^="right"],
  &.dropdown-menu[data-popper-placement^="top"],
  &.dropdown-menu[data-popper-placement^="left"] {
    top: auto !important;
    animation: none !important;
  }
}

@keyframes DropDownSlide {
  100% {
    transform: translateY(0);
  }

  0% {
    transform: translateY(10px);
  }
}

// Dropdown Large (Custom)
@media (min-width: 576px) {
  .dropdown-lg {
    width: 320px;
  }
}

// Dropdown arrow hide
.dropdown-toggle {
  &.arrow-none {
    &:after {
      display: none;
    }
  }
}


.notification-list {
  margin-left: 0;

  .notification-title {
    background-color: transparent;
    padding: 15px 20px;
  }

  .notification-icon-badge {
    position: absolute;
    top: 16px;
    right: 14px;
  }

  .notify-item {
    padding: 10px 20px;

    &.unread-notification {
      background-color: var(--#{$prefix}light);
    }

    &.read-notification {
      background-color: transparent;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: var(--#{$prefix}light);
      }
    }

    .card-body {
      padding: 14px;

      .notification-close-btn {
        position: absolute;
        top: 3px;
        right: 5px;
      }

      .notification-item-title {
        margin: 0 0 2px;
      }

      .notification-item-title,
      .notification-item-subtitle {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 50%;
      color: $white;
    }

    .notify-details {
      margin-bottom: 0;
      overflow: hidden;
      margin-left: 45px;

      b {
        font-weight: 500;
      }

      small {
        display: block;
      }
    }
  }
}

.profile-dropdown {
  min-width: 170px;

  i,
  span {
    vertical-align: middle;
  }
}

.dropdown-item > .material-symbols-outlined {
  font-size: 1rem;
}