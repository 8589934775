.app-menu {
  top: 0;
  position: sticky;
  z-index: 1000;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  width: $leftbar-width;
  min-width: $leftbar-width;
  padding-bottom: $topbar-height;
  transition: $menu-transition;
  background: $menu-bg;
  box-shadow: $box-shadow;
  border-right: $card-border-width solid $card-border-color;
}

.logo-box {
  top: 0;
  position: sticky;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  height: $topbar-height;
  transition: $menu-transition;
  padding: 0 $menu-item-padding-x;

  .logo-lg {
    height: $logo-lg-height;
  }

  .logo-sm {
    height: $logo-sm-height;
  }

  .logo-sm {
    display: none;
  }

  .logo-light {
    display: none;
  }

  .logo-dark {
    display: block;
  }
}

.menu {
  padding-left: 0;
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: $menu-item-gap;
  padding-top: $menu-item-gap;

  ul {
    padding-left: 0;
    list-style-type: none;
  }

  > .menu-item {
    padding: 0 $menu-item-padding-x;
  }

  .menu-title {
    opacity: .6;
    cursor: default;
    font-size: calc($menu-item-font-size * 0.75);
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: $menu-item-color;
    font-weight: $font-weight-semibold;
    padding: $menu-link-padding-y calc($menu-link-padding-x + $menu-item-padding-x);
  }

  .menu-item {

    .menu-link {
      display: flex;
      align-items: center;
      list-style: none;
      position: relative;
      color: $menu-item-color;
      gap: $menu-link-item-gap;
      transition: $menu-transition;
      border-radius: var(--#{$prefix}border-radius);
      font-size: $menu-item-font-size;
      padding: $menu-link-padding-y $menu-link-padding-x;

      &[aria-expanded="true"],
      &.show,
      &:focus,
      &:hover {
        color: $menu-item-hover-color;
      }

      > .menu-icon {
        width: $menu-item-icon-size;
        font-size: $menu-item-icon-size;
        line-height: $menu-item-icon-size;
        transition: $menu-transition;

        svg {
          width: $menu-item-icon-size;
          height: $menu-item-icon-size;
        }
      }

      > .menu-icon + .menu-text {
        padding-left: 0;
      }

      > .menu-text {
        white-space: nowrap;
        transition: $menu-transition;
        font-size: $menu-item-font-size;
        line-height: $menu-item-font-size;
        padding-left: calc($menu-item-icon-size + $menu-link-item-gap);
      }

      > .menu-arrow {
        margin-left: auto;
        transition: $menu-transition;
        line-height: $menu-item-font-size;
        font-size: calc($menu-item-font-size * 1.18);

        &:before {
          content: "chevron_right";
          font-family: "Material Symbols Outlined";
        }
      }

      &[aria-expanded="true"] {
        > .menu-arrow {
          transform: rotate(90deg);
        }
      }
    }

    .sub-menu {
      display: grid;

      .menu-text {
        font-size: calc($menu-item-font-size * 0.95);
      }

      .sub-menu {
        padding-left: calc($menu-item-icon-size);
      }
    }

    &.menuitem-active {

      > .menu-link {
        color: $menu-item-active-color;

        &:not(.collapsed) {
          > .menu-arrow {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}

.menu-link-bottom {
  color: $menu-item-color !important;
  background-color: $menu-bg !important;
  position: absolute;
  bottom: 0;
  left: 0;
}