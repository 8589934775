// Prefix for :root CSS variables
$prefix: 'csis-';
$variable-prefix: $prefix;

$font-family-secondary:    'IBM Plex Sans',sans-serif;

$white: #fff;
$gray-100: #f3f7f9;
$gray-200: #f7f7f7;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #98a6ad;
$gray-700: #6c757d;
$gray-800: #343a40;
$gray-900: #323a46;
$black: #000;
$card-bg: #fff;
$footer-bg: #4C5253;

// color-variables
$blue: #08ACF2;
$primary: $blue;
$primary-dark: #0777A7FF;
$secondary: $gray-700;
$secondary-color: $gray-700;
$light: $gray-100;
$dark: $gray-900;

// Body background and Text color
$body-bg: #f5f6f8;
$body-color: #6c757d;
$body-emphasis-color: $black;
$body-secondary-color: $body-color; // Body Color
$body-secondary-bg: $white; // Card Background
$body-tertiary-color: rgba($body-color, .5);
$body-tertiary-bg: $gray-200;
$emphasis-color: $black;
$text-muted: var(--#{$prefix}gray);

$spacer: 1.5rem;

:root {
  --#{$prefix}box-shadow: 0px 0px 35px 0px #{rgba(154, 161, 171, 0.15)};
  --#{$prefix}box-shadow-sm: 0 0.75rem 6rem #{rgba(56, 65, 74,.03)};
  --#{$prefix}box-shadow-lg: 0 0 45px 0 #{rgba(0, 0, 0, 0.12)};
  --#{$prefix}box-shadow-inset: inset 0 1px 2px #{rgba($black, .075)};
  --#{$prefix}primary: #08ACF2;
  --#{$prefix}primary-dark: #0777A7FF;

  // Card Border
  --#{$prefix}theme-border-width: 0px;
}

// border-radius-variables
$border-radius: .25rem;
$border-radius-sm: .2rem;
$border-radius-lg: .3rem;
$border-radius-xl: 1rem;
$border-radius-2xl: 2rem;
$border-radius-pill: 50rem;

// Topbar
$topbar-height: 70px;
$topbar-bg: $primary;
$topbar-item-hover-color: $white;
$topbar-item-color: rgba(255, 255, 255, .6);

// Dropdown
$dropdown-item-padding-y: $spacer * .25;
$dropdown-item-padding-x: $spacer;

// SideNav
$menu-bg: #fff;
$menu-item-color: #{$gray-700};
$menu-item-hover-color: #{$primary};
$menu-item-active-color: #{$primary};
$menu-icon-twotone-fill: #{rgba($primary, 0.2)};
$menu-item-active-bg: #{rgba($primary, 0.2)};
$menu-item-padding-x: 10px;
$menu-link-item-gap: 10px;
$menu-item-gap: 5px;
$menu-item-padding-x: 10px;
$menu-item-font-size: 15px;
$menu-item-color: $gray-700;
$menu-link-padding-y: 10px;
$menu-link-padding-x: 15px;
$menu-link-item-gap: 10px;
$menu-item-hover-color: #{$primary};
$menu-item-icon-size: 16px;
$menu-item-active-color: #{$primary};

$leftbar-width: 240px;
$leftbar-width-md: 175px;
$leftbar-width-sm: 80px;
$leftbar-condensed-height: 1600px;

$menu-transition: all .1s ease-in-out;

$box-shadow: 0px 0px 35px 0px #{rgba(66, 72, 80, 0.15)};
$box-shadow-sm: 0 0.75rem 6rem #{rgba(56, 65, 74, 0.03)};
$box-shadow-lg: 0 0 45px 0 #{rgba(0, 0, 0, 0.12)};
$box-shadow-inset: inset 0 -0.1rem 0 #{rgba($black, 0.075)};

$logo-lg-height: 20px;
$logo-sm-height: 22px;

// Footer
$footer-height: 60px;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
);

// Case
$folder-item-bg: $gray-300;
$folder-item-content-bg: $white;
$folder-item-selected-bg: $gray-500;
$folder-item-hover-bg: $gray-400;

$grid-columns: 12;
$grid-gutter-width: 24px;
$grid-row-columns: 6;

// Components
$border-width: 1px;
$border-widths: (
        0: 0,
        1: 1px,
        2: 2px,
        3: 3px,
        4: 4px,
        5: 5px
);

$border-style: solid;
$border-color: $gray-300;
$border-color-translucent: rgba($black, .175);

// Card
$card-spacer-y: $spacer;
$card-spacer-x: $spacer;
$card-title-spacer-y: $spacer * .5;
$card-title-color: null;
$card-subtitle-color: null;
$card-border-width: var(--#{$prefix}theme-border-width);
$card-border-color: var(--#{$prefix}border-color);
$card-border-radius: var(--#{$prefix}border-radius);
$card-box-shadow: var(--#{$prefix}box-shadow-sm);
$card-inner-border-radius: subtract($card-border-radius, $card-border-width);
$card-cap-padding-y: $card-spacer-y * .75;
$card-cap-padding-x: $card-spacer-x;
$card-cap-bg: var(--#{$prefix}secondary-bg);
$card-cap-color: null;
$card-height: null;
$card-color: null;
$card-bg: var(--#{$prefix}secondary-bg);
$card-img-overlay-padding: $spacer;
$card-group-margin: $grid-gutter-width * .5;


// Tables
$table-cell-padding-y: .85rem;
$table-cell-padding-x: .85rem;
$table-cell-padding-y-sm: .5rem;
$table-cell-padding-x-sm: .5rem;

$table-cell-vertical-align: top;

$table-color: var(--#{$prefix}body-color);
$table-bg: transparent;
$table-accent-bg: transparent;

$table-th-font-weight: null;

$table-striped-color: $table-color;
$table-striped-bg-factor: .025;
$table-striped-bg: rgba($black, $table-striped-bg-factor);

$table-active-color: $table-color;
$table-active-bg-factor: .1;
$table-active-bg: rgba($black, $table-active-bg-factor);

$table-hover-color: $table-color;
$table-hover-bg-factor: .075;
$table-hover-bg: rgba($black, $table-hover-bg-factor);

$table-border-factor: .1;
$table-border-width: var(--#{$prefix}border-width);
$table-border-color: var(--#{$prefix}border-color);

$table-striped-order: odd;
$table-striped-columns-order: even;

$table-group-separator-color: var(--#{$prefix}border-color);

$table-caption-color: $text-muted;

$table-bg-scale: -80%;
