.page-link {
  margin: 0 3px !important;
  border: none;
}

.pagination-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 1.5rem 5rem;
}